// this file is generated automatically, do not change anything manually in the contents of this file

export const ICONS_VERSION = "432df4691c08b949628703fb09f464df";

export const ICONS = {
    "arrow-left": { w: 448, h: 512 },
    "arrow-right": { w: 448, h: 512 },
    "calendar": { w: 448, h: 512 },
    "camera": { w: 512, h: 512 },
    "caretDown": { w: 320, h: 512 },
    "clock": { w: 512, h: 512 },
    "bars": { w: 448, h: 512 },
    "check": { w: 512, h: 512 },
    "chevronDown": { w: 512, h: 512 },
    "chevronLeft": { w: 384, h: 512 },
    "chevronsLeft": { w: 512, h: 512 },
    "chevronRight": { w: 384, h: 512 },
    "chevronsRight": { w: 512, h: 512 },
    "chevronUp": { w: 512, h: 512 },
    "cloud": { w: 640, h: 512 },
    "copy": { w: 512, h: 512 },
    "ellipsis-vertical": { w: 128, h: 512 },
    "eye": { w: 576, h: 512 },
    "eye-slash": { w: 640, h: 512 },
    "file": { w: 384, h: 512 },
    "imageFile": { w: 384, h: 512 },
    "triangle-exclamation": { w: 512, h: 512 },
    "user": { w: 448, h: 512 },
    "videoFile": { w: 384, h: 512 },
    "xmark": { w: 384, h: 512 },
    "xmarkLarge": { w: 448, h: 512 },
    "at-sign": { w: 24, h: 24 },
    "bell": { w: 24, h: 24 },
    "check-circle": { w: 24, h: 24 },
    "instagram": { w: 24, h: 24 },
    "linkedin": { w: 24, h: 24 },
    "phone": { w: 24, h: 24 },
    "slack": { w: 24, h: 24 },
} as const;

export type IconsType = keyof typeof ICONS;

declare module "@uxf/ui/icon/theme" {
    interface IconsSet {
        "arrow-left": true;
        "arrow-right": true;
        "calendar": true;
        "camera": true;
        "caretDown": true;
        "clock": true;
        "bars": true;
        "check": true;
        "chevronDown": true;
        "chevronLeft": true;
        "chevronsLeft": true;
        "chevronRight": true;
        "chevronsRight": true;
        "chevronUp": true;
        "cloud": true;
        "copy": true;
        "ellipsis-vertical": true;
        "eye": true;
        "eye-slash": true;
        "file": true;
        "imageFile": true;
        "triangle-exclamation": true;
        "user": true;
        "videoFile": true;
        "xmark": true;
        "xmarkLarge": true;
        "at-sign": true;
        "bell": true;
        "check-circle": true;
        "instagram": true;
        "linkedin": true;
        "phone": true;
        "slack": true;
    }
}
