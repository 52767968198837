import { config } from "@config/config";
import { ICONS, ICONS_VERSION } from "@generated/icons";
import { twScreens } from "@generated/tw-tokens/tw-screens";
import { LocalizeProvider } from "@localize";
import "@ui/styles/globals.css";
import { COLOR_SCHEME_DEFAULTS } from "@uxf/ui/color-scheme";
import { UiContextProvider, UiContextType } from "@uxf/ui/context";
import { locale } from "dayjs";
import "dayjs/locale/cs";
import { AppProps } from "next/app";
import { Inter } from "next/font/google";
import Head from "next/head";

locale("cs");

const UI_PROVIDER_CONFIG: UiContextType = {
    colorScheme: {
        defaultColorScheme: "light",
        getUserSettings: COLOR_SCHEME_DEFAULTS.getUserSettings,
        respectSystemSetting: false,
        respectUserSettings: false,
        setUserSettings: COLOR_SCHEME_DEFAULTS.setUserSettings,
    },
    domain: config.FRONTEND_URL ?? "",
    icon: { spriteFilePath: `/icons-generated/_icon-sprite.svg?v=${ICONS_VERSION}`, iconsConfig: ICONS },
    rasterImage: { breakpoints: twScreens },
};

const fontInter = Inter({
    adjustFontFallback: true,
    preload: true,
    subsets: ["latin", "latin-ext"],
    variable: "--font-inter",
    weight: ["400", "500", "600", "700"],
});

function UxfApp(props: AppProps) {
    return (
        <>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx global>
                {`
                    :root {
                        --font-inter: ${fontInter.style.fontFamily};
                    }
                `}
            </style>
            <Head>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                />
            </Head>
            <LocalizeProvider value="cs">
                <UiContextProvider value={UI_PROVIDER_CONFIG}>
                    <props.Component {...props.pageProps} />
                </UiContextProvider>
            </LocalizeProvider>
        </>
    );
}

export default UxfApp;
